import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"

const Introduction = styled.div`
    display:grid;
    grid-template-columns:repeat(10,10vw);
    grid-template-rows:repeat(10,10vh);
    background:url(${props=>props.image});
    background-size:cover;

    @media(max-width:640px){
        width:100vw;
        height:100vh;
        overflow:hidden;
    }
`
const BackHome = styled.div`
    width:3.2vw;
    height:3.2vw;
    border-radius:4vw;
    background:var(--brown_dark);
    margin-left:10vw;
    margin-top:20vh;
    position:fixed;

    @media(max-width:640px){
        width:10vw;
        height:10vw;
        border-radius:10vw;
        margin:10vh 0;
    }
    `

const IntroductionContent = styled.div`
    max-width:40vw;
    grid-column:3/10;
    grid-row:4/10;
    color:var(--brown_dark);

    @media(max-width:640px){
        max-width:80vw;
        grid-column:2/10;
        grid-row:3/10;
    }
`
/*个人基础简介*/
const Title = styled.h1`
    font-family:var(--header-font-family);
    font-size:var(--Caption);
    letter-spacing:0.4px;
    font-weight:400;

    span{
        font-weight:700;
    }
    
    `

const Text = styled.h2`
    font-family:var(--header-font-family);
    font-size:var(--H2);
    line-height:1.618;
    font-weight:400;
    opacity:0.6;

    span{
        font-weight:700;
    }
    @media(max-width:640px){
        font-size:var(--H3);
    }
    `

/*服务公司*/
const CompanyCollection = styled.div`
    display:grid;
    grid-template-columns:repeat(4,1fr);
    width:14vw;
    margin:4vh 0;

    @media(max-width:640px){
        width:32vw;   
        grid-column-gap:2vw;         
    }
    `

const Company01 = styled.div`
    background:url(${props=>props.image});
    background-size:cover;
    width:1.8vw;
    height:0.9vw;

    @media(max-width:640px){
        width:8vw;
        height:4vw;
    }
    `
const Company02 = styled.div`
    background:url(${props=>props.image});
    background-size:cover;
    width:1.8vw;
    height:0.9vw;
    @media(max-width:640px){
        width:8vw;
        height:4vw;
    }
    `
const Company03 = styled.div`
    background:url(${props=>props.image});
    background-size:cover;
    width:1.8vw;
    height:0.9vw;
    @media(max-width:640px){
        width:8vw;
        height:4vw;
    }
    `
const Company04 = styled.div`
    background:url(${props=>props.image});
    background-size:cover;
    width:1.8vw;
    height:0.9vw;
    @media(max-width:640px){
        width:8vw;
        height:4vw;
    }
    `

const BottomContent = styled.div`
    grid-column:3/10;
    grid-row:10/10;
    display:grid;
    grid-template-columns:repeat(2,1fr);

    @media(max-width:640px){
        grid-template-columns:repeat(1,1fr);
        grid-column:2/10;
        grid-row:9/10;        
    }
    `

/*社交链接*/
const SocialLinks = styled.div`
    display:grid;
    grid-template-columns:repeat(5,1fr);
    grid-gap:2vw;
    max-width:20vw;
    grid-gap:1.6vw;
    font-family:var(--body-font-family);
    font-weight:600;
    font-size:var(--Body);
    color:var(--brown_dark);

    justify-items:center;
    align-items:center;
    
    `

const Link01 = styled.p`
    background-image:linear-gradient(to bottom, transparent 50%, #333 50%);
      background-position:top;
      background-size:100% 200%;
      transfrom:translateY(0px)
    &:hover{
        background-position:bottom;
        color:#fff;
        transfrom:translateY(40px)
    }
   `
const Link02 = styled.p`
    &:hover{
        color: var(--brown_green);
    }`
const Link03 = styled.p`
     &:hover{
        color: var(--brown_green);
    }
    `
const Link04 = styled.p`
     &:hover{
        color: var(--brown_green);
    }
`
const Link05 = styled.p`
     &:hover{
        color: var(--brown_green);
    }    
`

const Addtion = styled.p`
    font-family:var(--body-font-family);
    justify-self:end;
    font-size:var(--Addtion);
    font-weight:600;
    color:var(--brown_dark);
    opacity:0.6;
    align-self:center;
    justify-content:end;

    @media(max-width:640px){        
        justify-self:start;
    }
    `

const CricleCollection = styled.div`
   background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 0%, #FFFFFF 48%, rgba(255, 255, 255, 0.6) 80%, rgba(255, 255, 255, 0.4) 100%);   
   width:100vw;
   height:100vh
    `

const Circle01 = styled.div`
    width:40vh;
    height:40vh;
    display:grid;
    grid-column:5/10;
    grid-row:3/10;
    justify-items:center;
    align-items:center;
    
    border:6vh solid var(--grey03);
    border-radius:40vh;  
`

const Circle02 = styled.div`
    width:28vh;
    height:28vh;
    border-radius:28vh;
    border:6vh solid #35636E;
    @media(max-width:640px){        
        opacity:0.2
    }
    `

const Me = props =>(
    <div>
    
        <Introduction image={props.base}>
        
        <Circle01>
            <Circle02></Circle02>
        </Circle01>
        <CricleCollection></CricleCollection>
                
            
            <Link to="/"><BackHome></BackHome></Link>
            <IntroductionContent>
                <Title>Hello, My name is <span>yhmnin.</span></Title>
                <Text>I'm a <span>Product Designer</span> passionate about creating <span>memorable online experiences</span> and <span>meaningful products.</span></Text>
                <CompanyCollection>
                    <Company01 image={props.image01}></Company01>
                    <Company02 image={props.image02}></Company02>
                    <Company03 image={props.image03}></Company03>
                    <Company04 image={props.image04}></Company04>
                </CompanyCollection>
            </IntroductionContent>
            <BottomContent>
                <SocialLinks>
                    <Link href="mailto:yhmnin@gmai.com?subject=Mail from yhmnin Site"><Link01>{props.link01}</Link01></Link>
                    <Link href="https://dribbble.com/yhmnin" target="_blank"><Link02>{props.link02}</Link02></Link>
                    <Link href="https://www.behance.net/yhmnindc9d" target="_blank"><Link03>{props.link03}</Link03></Link>
                    <Link href="https://twitter.com/yhmnin" target="_blank"><Link04>{props.link04}</Link04></Link>
                    <Link href="https://www.instagram.com/yhmmin/" target="_blank"><Link05>{props.link05}</Link05></Link>
                </SocialLinks>
                <Addtion>{props.addtion}</Addtion>
            </BottomContent> 
            
        </Introduction>
    </div>
)

export default Me