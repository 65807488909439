
import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Me from "../components/Me"

const LastPage = () => (
  
    <div className="fullpage">
        <Me 
            base="https://yhmnin.oss-cn-beijing.aliyuncs.com/background_wave.jpg"
            title="Hello, My name is yhmnin."
            text="I'm a Product Designer passionate about creating memorable online experiences and meaningful products."

            image01="https://yhmnin.oss-cn-beijing.aliyuncs.com/icon_red_grey.png"
            image02="https://yhmnin.oss-cn-beijing.aliyuncs.com/icon_bytedance_grey.png"
            image03="https://yhmnin.oss-cn-beijing.aliyuncs.com/icon_smartisan_grey.png"
            image04="https://yhmnin.oss-cn-beijing.aliyuncs.com/icon_moreTech_grey.png"
            link01="Email"
            link02="Dribbble"
            link03="Behance"
            link04="Twitter"
            link05="Instagram"

            addtion="2020 Copyright ©yhmnin 🏂Product Designer."

            
        />
    </div>
    
  
)

export default LastPage
